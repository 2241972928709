import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import moment from 'moment';

let initialLoad = true

let currentDate = null
let popupsContainer = null
let openPopup = null

const showMonthsInPast = 12
const showMonthsInFuture = 12

const start = moment().subtract(showMonthsInPast, 'months')
const end = moment().add(showMonthsInFuture + 1, 'months')

const calendarContainer = document.querySelector('.wm-calendar')
const calendarEventFeeds = JSON.parse(calendarContainer.dataset.eventFeeds)

const calendarSettings = {
	schedulerLicenseKey: '0355373550-fcs-1668078563',
	plugins: [dayGridPlugin],
	initialView: 'dayGridMonth',
	validRange: {
		start: start.format('YYYY-MM-[01]'),
		end: end.format('YYYY-MM-[01]'),
	},
	weekends: false,
	stickyHeaderDates: false,
	height: 'auto',
	customButtons: {},
	headerToolbar: {
		left: null, // Top
		center: 'prev,next', // Center
		right: null, // Bottom
	},
	eventDisplay: 'block',
	eventSources: [],
	eventClick: (args) => {
		if (openPopup !== null) {
			openPopup.classList.remove('open')
		}
		
		const arrowOffset = 12
		
		openPopup = calendarContainer.querySelector(`.wm-event-popup[data-event-id="${args.event.id}"`)
		openPopup.classList.add('open')
		
		const arrow = openPopup.querySelector('.wm-event-arrow')
		
		const calendarBounds = calendarContainer.getBoundingClientRect()
		const eventBounds = args.el.getBoundingClientRect()
		const popupBounds = openPopup.getBoundingClientRect()
		const clickEvent = args.jsEvent
		
		let left = clickEvent.x - calendarBounds.x + arrowOffset
		
		if (left > (calendarBounds.width - popupBounds.width)) { // Popup would extend beyond the right edge of the calendar so position it to the left
			left = clickEvent.x - calendarBounds.x - popupBounds.width - arrowOffset
			
			arrow.style.left = null;
			arrow.style.right = 0;
			arrow.style.transform = 'translate(50%, -50%) rotate(45deg)'
		} else {
			arrow.style.right = null;
			arrow.style.left = 0;
			arrow.style.transform = 'translate(-50%, -50%) rotate(45deg)'
		}
		
		left = clamp(left, 10, calendarBounds.width - popupBounds.width - arrowOffset)
		
		let top = clamp(eventBounds.y - calendarBounds.y - (popupBounds.height / 2), 0, calendarBounds.height - popupBounds.height)
		
		openPopup.style.left = `${left}px`
		openPopup.style.top = `${top}px`
		
		const arrowTop = eventBounds.y - calendarBounds.y + (eventBounds.height / 2) - top
		
		arrow.style.top = `${arrowTop}px`
	},
	eventDidMount: (args) => {
		const popup = document.createElement('div')
		popup.classList.add(`wm-${args.event.groupId}-event-popup`)
		popup.classList.add('wm-event-popup')
		popup.dataset.eventId = args.event.id
		
		const arrow = document.createElement('div')
		arrow.classList.add('wm-event-arrow')
		
		const closeButton = document.createElement('button')
		closeButton.classList.add('wm-close-button')
		closeButton.innerHTML = '\u00D7'
		closeButton.addEventListener('click', () => {
			popup.classList.remove('open')
		})
		
		const content = document.createElement('div')
		content.classList.add('wm-event-content')
		content.innerHTML = `
			<div class="wm-event-header">${args.event.extendedProps.header}</div>
			<h3 class="wm-event-title">${args.event.title}</h3>
			<div class="wm-event-description">${args.event.extendedProps.description}</div>
			<div class="wm-event-buttons">${args.event.extendedProps.buttons}</div>
		`
		
		popup.appendChild(arrow)
		popup.appendChild(closeButton)
		popup.appendChild(content)
		
		popupsContainer.appendChild(popup)
	},
	eventsSet: (args) => {
		if (currentDate !== calendar.currentData.currentDate) {
			popupsContainer.innerHTML = ''
			currentDate = calendar.currentData.currentDate
			
			if (dataLayer !== undefined) {
				dataLayer.push({
					'event': 'wxc.monthLoad',
					'wxc.month': moment(currentDate).format('MMMM YYYY'),
					'wxc.initialLoad': initialLoad,
				})
				
				initialLoad = false
			}
		}
	},
	viewDidMount: (args) => {
		popupsContainer = document.createElement('div')
		popupsContainer.classList.add('wm-popups')
		
		calendarContainer.appendChild(popupsContainer)
		
		const selectContainer = document.createElement('div')
		selectContainer.classList.add('wm-month-select')
		
		const select = document.createElement('select')
		const selectedOption = moment(calendar.currentData.currentDate).format('YYYY-MM-[01]')
		
		for (let date = start; date.diff(end, 'months') < 0; date.add(1, 'months')) {
			const option = document.createElement('option')
			option.value = date.format('YYYY-MM-[01]')
			option.innerHTML = date.format('MMMM YYYY')
			
			if (option.value === selectedOption) {
				option.selected = true
			}
			
			select.appendChild(option)
		}
		
		select.addEventListener('change', (event) => {
			calendar.gotoDate(event.target.value)
		})
		
		selectContainer.appendChild(select)
		
		const nextButton = calendarContainer.querySelector('.fc-next-button')
		nextButton.parentNode.insertBefore(selectContainer, nextButton)
	},
	datesSet: (args) => {
		const select = document.querySelector('.wm-month-select select')
		select.value = moment(calendar.currentData.currentDate).format('YYYY-MM-[01]')
	}
}

if (calendarContainer.classList.contains('wm-event-dots')) {
	calendarSettings.eventContent = (args) => {
		return {
			html: `
				<div class="fc-event-main-frame">
					<div class="fc-event-title-container">
						<div class="fc-daygrid-event-dot" style="border-color: ${args.borderColor};"></div>
						<div class="fc-event-title fc-sticky">${args.event.title}</div>
					</div>
				</div>
			`
		}
	}
}

for (const feed of calendarEventFeeds) {
	const feedName = feed.label.toLowerCase()
	const feedFilterButtonName = `feed-filter-${feedName}`
	
	if (calendarSettings.headerToolbar.right === null) {
		calendarSettings.headerToolbar.right = feedFilterButtonName
	} else {
		calendarSettings.headerToolbar.right += `,${feedFilterButtonName}`
	}
	
	const eventSource = {
		url: feed.url,
		id: feedName,
		color: feed.backgroundColor,
		borderColor: feed.accentColor,
		textColor: feed.textColor,
		extraParams: extraParams,
	}
	
	calendarSettings.eventSources.push(eventSource)
	
	// Filter button
	calendarSettings.customButtons[feedFilterButtonName] = {
		text: feed.label,
		click: (event) => {
			if (event.target.dataset.active === 'false') {
				calendar.addEventSource(eventSource)
				event.target.dataset.active = 'true'
			} else {
				calendar.getEventSourceById(eventSource.id).remove()
				event.target.dataset.active = 'false'
			}
		},
	}
}

const calendar = new Calendar(calendarContainer, calendarSettings)

calendar.render()

window.addEventListener('resize', () => {
	if (openPopup !== null) {
		openPopup.classList.remove('open')
	}
})

function clamp(number, min, max) {
	return Math.min(Math.max(number, min), max)
}
